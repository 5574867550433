@import 'bootstrap/'

$charcoal = #262626
$grey = #4D4D4D
$mariner = #2D7CBD
$boston-blue = #4289C4
$danube = #5796CA
$med-blue = #0C65AD

 

$display = 'Montserrat', Helvetica, Arial, sans-serif 
$web = 'Titillium Web', Helvetica, Arial, sans-serif

/*Mixins*/
bg(img)
    background-image url('../../../img/'+img)
    background-size cover 
    -webkit-background-size cover
    -moz-background-size cover
    background-position center center  

	
$transition(props = all, speed = .3s)
    transition props speed
	
$callout-hover(color, opacity = .9)
    background-color color
    $transition()
    &:hover 
        background-color rgba(color, opacity); 
        $transition()
		
$next-arrow(fsz = 20px)
    if @position != 'relative' || 'absolute'
        position relative
    if @display != 'block' || 'inline-block'
        display inline-block
    &:after
        content '\203A'
        font-size fsz * 1.8
        position absolute
        top -.7 * fsz
        right -1.25 * fsz 
		
body 
    font-family $web

img
    max-width 100%
    height auto
header,
footer
    background $charcoal
	
     
.broadcast-software header .broadcast-data,
.mediastar-suite header .broadcast-data,
.tv-data-services header .broadcast-data,
.psip-data-services header .broadcast-data,
.guide-services header .broadcast-data,
.digital-signal-prediction header .signal-prediction,
.consumer-marketing header .consumer-marketing,
.about-us header .about
    color $mariner  
    
header
    height 80px
    .container-fluid
        padding 0 
    .navbar-brand
        padding 13px 13px 13px 28px
        height auto

    .navbar-toggle
        margin 22px 10px 
        .icon-bar
            background-color #fff

    .navbar-right,
    .short-menu
        li
            a
                padding 30px 25px
                display inline-block
                color #fff
                font-weight 700
                font-family $display
                text-transform uppercase
                $transition()
                &:hover
                    background unset
                    color $mariner
                    $transition()
                &:focus,
                &:active
                    background unset
                    color $mariner
            &.walled
                border-left 1px solid rgba(#fff, .22)
                $transition()
                a
                    color $mariner
                    $transition()
                &:hover
                    background-color $mariner
                    $transition()
                    a
                        color #fff
                        $transtion()
            .dropdown-menu
                    padding 0
                    background transparent
                    border 0
                    li                        
                        a
                            width 100%
                            border-top 1px solid rgba(255,255,255,0.22)
                            padding 15px 25px
                            color #fff
                            background $charcoal
                            &:hover
                                background $mariner
                                $transition()
    .open > a
        &,
        &:hover,
        &:focus
            background $boston-blue
            color #fff
            $transition()

#home .hero
    top -1px
    display flex
    align-items center
    &:before
        content ""
        position absolute
        top 0
        right 0
        bottom 0
        left 0
        bg('homepage/homepage_banner_dec_2020.jpg')
        /* bg('homepage/homepage_hero.jpg') */       
        z-index -1
.hero
    position relative
    height 650px
    width 100%    
    &:after
        content ""
        position absolute
        top 0
        right 0
        left 0
        bottom 0
        z-index -1

    h1
        color #fff
        font-size responsive 36px 75px
        font-weight 700
        font-family $display
        text-align center
        text-shadow 1px 1px 3px rgba(#000, .3)
        max-width 1200px
        margin 0 auto
        padding 0 50px
        text-transform uppercase
        display block
		
.adjust-up
    margin-top -1px
		

.home-callouts
    display flex
    
    .icon-holder
        // width 75%
        text-align center
        margin 0 auto

    .callout
        a
            padding 50px 50px 75px
            +above(1199px)
                    display flex
                    align-items center
                    justify-content center
            $transition()
            text-decoration none

    .callout1
        $callout-hover($mariner)
    .callout2
        $callout-hover($boston-blue)
    .callout3
        $callout-hover($danube)

    .callout-content
        max-width 90%
        padding-left 20px
        color #fff
        h2
            font-size 24px
            font-family $display
            text-transform uppercase
        p
            font-size 20px
            font-weight 300
            font-family $web
        a
            color #fff
            font-size 20px
            font-family $display
            text-transform uppercase
            $next-arrow()
            &:hover
                text-decoration none
        span.learn-more
            color #fff
            font-size 20px
            position relative
            display inline-block
            text-transform uppercase
            font-family $display
            $next-arrow()

.secondary
    h1
        font-size 50px
        color $grey
        text-transform uppercase
        font-family $display
    h2
        font-size 32px
        color $boston-blue
        text-transform uppercase
        font-family $display
        margin-top 35px
    h3 
        font-size 26px
        color $grey
        text-transform uppercase
        font-family $display
        margin-top 25px
    h4
        font-size 22px
        color $boston-blue
        font-family $web
        margin-top 25px
        font-weight 700
    p
        font-size 20px
        line-height 32px
        margin-bottom 15px
        a
            color $med-blue
            font-weight 700    
    .container
        width 100% !important
        max-width 1600px
    .content-wrapper
        padding 50px 25px 0 25px
    .cell-content
        min-height 650px
        padding 0 45px 45px 15px
        margin-bottom 75px
        border-right 1px solid rgba(#4D4D4D, .35)
        section
            padding 35px 0 50px 0
            overflow hidden
            h2
                margin-top 0
                display inline-block
            .content-img
                float right
                text-align center
                display none
            .content-img.col-lg-4
                display block
            .section-content.col-lg-8
                padding-left 0
        ul
            font-size 20px
            line-height 32px 
        ol 
            font-size 20px
            line-height 32px 
            li
                padding-bottom 5px 
        .enlarge-images
            padding 15px 0
            overflow hidden
            .img
                padding 0 15px 0 0
            .modal                
                margin 0 auto
                .modal-body
                    text-align center
                .modal-dialog
                    max-width 960px
            .small
                font-size 85%
        //.content-img-lg
            //text-align center
        .caption
            float left
            font-size 85%
            width 100%
            font-weight 700
        .video-wrapper
            position relative
            padding-bottom 56.25% /*16:9*/
            padding-top 25px
            margin 25px 0
            height 0
            iframe
                position absolute
                top 0
                left 0
                width 100%
                height 100%
    .sidebar
        padding 0 0 0 35px
        h3
            font-family $display
            text-transform uppercase  
            color $grey
            margin 15px 0
        ul
            list-style-type none
            padding 0 
            margin 0
            li   
                border-bottom 1px solid rgba(#4D4D4D, .15) 
                &.current
                    > a
                        color $mariner
                &:first-child
                    border-top 1px solid rgba(#4D4D4D, .15)
                a
                    font-weight 700
                    color $grey
                    font-size 22px
                    padding 10px 0
                    display block  
                    .caret
                        color $boston-blue
                        transform rotate(-90deg)
                    &:hover, 
                    &:focus
                        color $mariner
                        text-decoration none
                ul
                    li
                        a
                            font-size 18px
                            padding 5px 0 5px 15px
                        &:last-child
                            border-bottom 0

        .sidebar-cta
            margin-top 75px
            a
                display inline-block
                font-weight 700
                color $boston-blue
            h3
                margin-bottom 5px
                        
    .hero
        height 500px
        padding 0
        background-size cover 
        -webkit-background-size cover 
        -moz-background-size cover
        background-position center center
        background-repeat no-repeat
        &:after
            z-index unset
    .contact-cta
        h2 
            color white
        background-color $boston-blue
        padding 50px 
        color white
        a
            display inline-block
            color $grey
            font-weight 700
    .btn-primary
        background-color $med-blue
        font-size 14px
        color white
        font-family $display
        text-transform uppercase
        font-weight 700
        padding 10px
        display inline-block
        margin-top 15px
        border 0
        &:hover,
        &:focus
            background $danube
            $transition()
            text-decoration none
    hr
        border-top 1px solid rgba(#4D4D4D, .15)
        margin 25px 0
    .breadcrumbs
        background-color $mariner
        padding 8px 25px
        .breadcrumb
            margin 0
            padding 0      
            background-color initial
            li
                padding-right 5px
                a                    
                    color #B6D1E8
                    text-transform uppercase
                    font-family $display                
                &:after,
                &:before
                    color #B6D1E8
                    font-weight 700
                    text-transform uppercase
                    font-family $display  
            .active,
            .active-breadcrumb-item
                color #fff
                text-transform uppercase
                font-family $display 
                &:after,
                &:before
                    color #fff
    .table-panel
        .panel-body
            padding 25px 0 0 0
    .content-table
        h4
            font-size 20px
            color $grey !important
            text-transform uppercase
            font-family $display
            margin 0 0 5px 0
        .headers
            overflow hidden
            padding-right 15px
        ul
            list-style-type none
            padding 0
            margin 0
            font-size 18px
            line-height 23px
            li
                overflow hidden
                padding 15px
                &:nth-child(even) 
                    background-color rgba(#95989A, .10)
            .base-col,
            .data-col-1,
            .data-col-2,
            .data-col-3
                float left
    .four-col-tbl
        .base-col
            width 50%
        .data-col-1,
        .data-col-2,
        .data-col-3
            width 16.66667%
            text-align center
            color $mariner
    .three-col-tbl
        .base-col
            width 75%
        .data-col-1,
        .data-col-2
            width 12.5%
            text-align center
            color $mariner
    .form-iframe
        position relative
        padding-bottom 56.25%
        padding-top 30px
        height 0
        overflow hidden
        iframe
            position absolute
            top 0
            bottom 0
            right 0
            left 0
            width 100%
            height 100%
            
    &.mediastar-scheduler
        .partner-logos
            h3
                margin-top 75px
            img
                width auto
                max-width 100px
                margin 10px 20px 10px 0
.broadcast-software.secondary
    .hero
        background-position 70% 50%
.digital-signal-prediction.secondary
    .hero
        background-position 33% 50%
.consumer-marketing.secondary
    .hero
        background-position 100% 50%
                
.request-information
    .form-iframe
        height 1100px
            
.std-form
    .field
        margin-bottom 15px
        line-height 32px
        font-size 20px
    label
        font-size 20px
    input[type=text]
        width 100%
        max-width 400px
    textarea
        height 200px
        width 100%
        max-width 600px


.client-icons
    overflow hidden
    clear right
    img
        max-height 80px
        float left
        margin 15px
        
.contact-us
    .sidebar-cta
        display none
            
.staff
    .modal
        width 100%
        max-width 960px
        margin 0 auto
        .modal-dialog
            margin 50px 15px
    .person
        padding 15px 30px 0 0
        margin-bottom 45px
        .name
            margin 15px 0 0 0
            font-size 24px
            font-weight 700
            color $mariner
        .title 
            margin 0
    hr
        margin 0
.blog
    .cell-content    
        section
            border-bottom 1px solid rgba(77,77,77,0.15)
            h2
                margin 25px 0 15px 0            
            &:last-of-type
                border-bottom 0
    .sidebar
        .widget
            margin-bottom 50px
        #recent-comments-2
            display none
        .widget_search
            label
                display block
                font-size 18px
            input
                height 35px
            input[type=text]
                width 70%
                margin-top 2px
            input[type=submit]
                color white
                background-color $mariner
                border 0
                top -1px
                display inline-block
                position relative
                font-weight 700
                font-family $display
                text-transform uppercase
                &:hover,
                &:focus
                    background-color $danube
    .comments-list 
        list-style none
        margin 0
        padding 0	
        .children
            list-style none
        .comment-body
            padding 24px 24px 24px 0
            .avatar 
                float left
                width 50px
                height auto 
                margin 0 24px 12px 0 
                .comment-meta
                    font-size 12px 
    .comment-respond label
        display block
    .comment-respond input,
    .comment-respond textarea
        display block 
        margin 2px 0 12px 0
    .page-nav
        a
            padding-right 10px
        h2
            display none
        .page-numbers
            font-size 16px
            padding-right 10px
            color $boston-blue
            font-weight 700
        .page-numbers.current 
            color $grey
    .contact-cta
        clear both           
         
.close
    float right
    font-size ($font-size-base * 1.5)
    font-weight $close-font-weight
    line-height 1
    color $close-color
    text-shadow $close-text-shadow
    opacity-ie(.2)

    &:hover,
    &:focus
        color $close-color
        text-decoration none
        cursor pointer
        opacity-ie(.5)
footer
    color #fff
    .col-md-6
        padding 0

    a
        $transition()
        &:hover
            opacity .5
            text-decoration none
            $transition()        

    .footer-top
        padding 45px 100px 40px
        overflow hidden
        strong
            font-size 18px
            font-family $display
            text-transform uppercase
        ul
            padding-left 0
            li
                list-style-type none
                font-size 20px
                font-weight 300
                font-family $web
            a[href^="tel:"]
                display inline-block
        a
            font-weight 700
				
	
	.social
            text-align right
            cursor default
            a
                display inline-block
                cursor pointer
                img
                    padding-left 15px

	.footer-bottom
            padding 30px 100px
            background #000
		
	.credit
            display inline-block
            font-size 16px
            font-family $web
            a
                display inline-block
                color #fff
		
	.other-links
            float right
            ul
                display inline-block
                padding-left 0
                margin-bottom 0
                li
                    list-style-type none
                    display inline-block
                    font-family $web
                    margin-left 25px
                    a
                        color #fff
                        

.atsc-3-0-services
    video
        max-width 100%

//MEMBERSHIP SECTION ON MISSION STATEMENT PAGE
.mission-statement
    .membership-container
        display flex
        flex-flow row wrap
        .membership
            width 250px
            padding-bottom 35px


.gsc-control-searchbox-only {
    background: none;
    width: 300px;
    border: none;
    padding: 22px 0;
}
.gsc-search-button {
    height: 33px;
}
.gsst_a {
    padding: 0 9px !important;
}

.mediastar-suite .cell-content section .section-content.col-lg-8 {
    width 100% !important
}

+below(1540px)
    header
        .navbar-right
            li
                a
                    padding 30px 10px
                    font-size 12px
                .dropdown-menu
                    li
                        a
                            padding 15px 25px
    .secondary
        .four-col-tbl
            h4
                font-size 16px
            .base-col
                width 40%
            .data-col-1,
            .data-col-2,
            .data-col-3
                width 20%
            ul
                font-size 16px
        .three-col-tbl
            h4
                font-size 16px
            .base-col
                width 60%
            .data-col-1,
            .data-col-2
                width 20%
            ul
                font-size 16px
    .blog
        .sidebar
            #search-2
                input[type=text]
                    width 100%
                    margin-bottom 10px

                    
+below(1199px)
    .home-callouts
        .callout-content
            padding-left 0
        .callout
            padding 25px
    .secondary
        .cell-content
            section
                .content-img
                    float none
                    margin 0 0 15px 0

+below(1256px)
    header
        .navbar-header
            margin-bottom -1px
        .navbar-collapse
            position relative
            padding-right 0
            padding-left 0
            width 100%
            background $charcoal
            border-top 0
            z-index 1

        .navbar-right
            > li
                .gsc-control-searchbox-only,
                a
                    padding 15px 25px
                    width 100%
                &.walled
                    border-left 0
                &:first-of-type
                    margin-top 15px
                &:last-of-type
                    margin-bottom 15px					

+below(1050px)
    .home-callouts
        .icon-holder
            display block
            text-align center
        .callout-content
            padding-left 0
    .staff
        .person
            .name
                font-size 22px 
            .title
                font-size 18px
                line-height 26px
			

+below(991px)
    .hero
        height 75vh
        min-height 400px
        h1
            // font-size 65px
            padding 15% 25px 0 25px
    .home-callouts
        display unset
        .callout
            display flex
            .icon-holder
                display flex
                align-items center
                justify-content center
                min-width 20%
                width auto
            h2
                margin-top 0
                
    .secondary 
        h1
            font-size 34px
        h2
            font-size 28px
        h3
            font-size 22px
        p
            font-size 18px
            line-height 30px
        .hero
            height 55vh
            min-height 350px
        .breadcrumbs
            padding 8px 10px
            .container
                padding 0 10px
            .breadcrumb
                li
                    padding-right 0
                    font-size 12px
                    &:before
                        padding 0 0 0 4px
        .cell-content
            min-height 0
            border-right 0
            padding 0
            section
                padding 15px 0 25px 0
            margin-bottom 50px
            section
                .content-img
                    float none
                    margin 0 0 15px 0
                    text-align center
        .sidebar
            border-top 1px solid rgba(77,77,77,0.35)
            padding 35px 0
            h3
                font-size 22px
            ul
                li
                    a
                        font-size 18px
            .sidebar-cta
                margin-top 50px
        .contact-cta
            padding 45px 20px
        .four-col-tbl
            h4
                font-size 16px
            h4.data-col-1,
            h4.data-col-2,
            h4.data-col-3
                display none
            .base-col
                width 100%
                float none
                padding-bottom 5px
                font-size 18px
                line-height 23px
                font-weight 700
            .data-col-1,
            .data-col-2,
            .data-col-3
                width auto
                text-align left
                margin 0 25px 10px 0
        .three-col-tbl
            h4
                font-size 16px
            h4.data-col-1,
            h4.data-col-2
                display none
            .base-col
                width 100%
                float none
                padding-bottom 5px
                font-size 18px
                line-height 23px
                font-weight 700
            .data-col-1,
            .data-col-2
                width auto
                text-align left
                margin 0 25px 10px 0
        .manager-comp
            .data-col-1,
            .data-col-2,
            .data-col-3
                &:before                    
                    margin-right 5px
            .data-col-1
                &:before
                    content 'EDITOR:'
            .data-col-2
                &:before
                    content 'MANAGER:'
            .data-col-3
                &:before
                    content 'SCHEDULER:'
        .guide-comp
            .data-col-1,
            .data-col-2,
            .data-col-3
                &:before                    
                    margin-right 5px
            .data-col-1
                &:before
                    content 'BASIC:'
            .data-col-2
                &:before
                    content 'PREMIUM:'
            .data-col-3
                &:before
                    content 'MOBILE:'
        .compliance-comp
            .data-col-1,
            .data-col-2
                &:before                    
                    margin-right 5px
            .data-col-1
                &:before
                    content 'BASIC:'
            .data-col-2
                &:before
                    content 'PLUS:'

    .blog 
        .sidebar
            .widget
                margin-bottom 35px

    footer
        .footer-top,
        .footer-bottom
            padding 45px 35px
        .social
            margin-top 25px
            text-align left
            a 
                img
                    padding-left 0
                    padding-right 15px

        .other-links
            float none
            margin-top 25px
            ul li
                margin-left 0
                margin-right 25px
				
+below(768px)
    .home-callouts
        .callout
            display unset
            text-align center
            .icon-holder
                display block
            h2
                margin-top 20px
            p
                margin auto auto 20px
                max-width 50ch
    .secondary
        .cell-content
            .enlarge-images
                .img
                    padding-bottom 15px
    .blog
        .comment-respond
            textarea
                width 100%
							
+below(500px)
    .hero
        h1
            // font-size 40px
            padding-top 0     
        .four-col-tbl
            .data-col-1,
            .data-col-2,
            .data-col-3 
                float none !important
        .three-col-tbl
            .data-col-1,
            .data-col-2
                float none !important
				
+below(419px)
    header
        .navbar-collapse
            max-width unset

        .dropdown-menu
            li
                margin 0
                a
                    padding 10px 35px 

    header
        .navbar-collapse
            li.open
                background-color $boston-blue 
                .dropdown-menu
                    li
                        a
                            padding 10px 35px
                        &:last-of-type a
                            margin-bottom 15px 
                            
    #home .hero
        height 59vh
        &:before
            background-position-x calc(50% - 42px)

    .broadcast-software.secondary .hero
        background-position 80% 50%
    .digital-signal-prediction.scondary .hero
        background-position 22% 50%
    
    .client-icons
        display flex
        flex-wrap flex-wrap
        justify-content space-around

.mediastar-suite .cell-content section .content-img.col-lg-4 {
    display none !important
}
